import { BookingApi } from '@/api/booking';
import {
  ICreateBookingResponse,
  IGetBookingsResponse,
  IGetSingleBookingResponse,
  ICreateBookingRequest,
  IGetSingleBookingRequest,
  IAcceptBookingResponse,
  IAcceptBookingRequest,
  IRejectBookingRequest,
  IStartBookingRequest,
  IStartBookingResponse,
  IRemoveBookingRequest,
  IRemoveBookingResponse,
  IUpdateBookingRequest,
  IUpdateBookingResponse,
  BookingCreate,
  IBookingRequestStatus,
  IGetMyBookingResponse,
} from '@/shared/interface';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, takeLatest, put } from 'redux-saga/effects';
import {
  createBooking,
  createBookingFailure,
  createBookingSuccess,
  getSingleBooking,
  getSingleBookingFailure,
  getSingleBookingSuccess,
  getBookings,
  getBookingsFailure,
  getBookingsSuccess,
  acceptBooking,
  acceptBookingSuccess,
  acceptBookingFailure,
  rejectBooking,
  rejectBookingSuccess,
  rejectBookingFailure,
  startBooking,
  startBookingSuccess,
  startBookingFailure,
  removeBooking,
  removeBookingSuccess,
  removeBookingFailure,
  updateBooking,
  updateBookingSuccess,
  updateBookingFailure,
  resetBookingState,
  getCreateBooking,
  getCreateBookingSuccess,
  getCreateBookingFailure,
  getMyBookingsSuccess,
  getMyBookingsFailure,
  getMyBookings,
} from './slice';
import * as actionTypes from './actionTypes';

function* createBookingRequest(action: PayloadAction<ICreateBookingRequest>) {
  try {
    yield put(createBooking());
    const res: ICreateBookingResponse = yield call(BookingApi.createBooking, action.payload);
    if (res.success) {
      const { data } = res;
      yield put(createBookingSuccess(data));
    } else {
      yield put(createBookingFailure(res.message));
    }
  } catch (error) {
    yield put(createBookingFailure(error?.toString()));
  }
}

function* getSingleBookingRequest(action: PayloadAction<IGetSingleBookingRequest>) {
  try {
    yield put(getSingleBooking());
    const res: IGetSingleBookingResponse = yield call(BookingApi.getSingleBooking, action.payload);
    if (res.success) {
      const { data } = res;
      yield put(getSingleBookingSuccess(data));
    } else {
      yield put(getSingleBookingFailure(res.message));
    }
  } catch (error) {
    yield put(getSingleBookingFailure(error?.toString()));
  }
}

function* getBookingsRequest(action?: PayloadAction<IBookingRequestStatus>) {
  try {
    yield put(getBookings());
    const res: IGetBookingsResponse = yield call(BookingApi.getBookings, action?.payload);
    if (res.success) {
      const { bookings } = res;
      yield put(getBookingsSuccess(bookings));
    } else {
      yield put(getBookingsFailure(res.message));
    }
  } catch (error) {
    const err = error as { message: string; meta: string };
    yield put(getBookingsFailure(err?.meta));
  }
}

function* acceptBookingRequest(action: PayloadAction<IAcceptBookingRequest>) {
  try {
    yield put(acceptBooking());
    const res: IAcceptBookingResponse = yield call(BookingApi.acceptBooking, action.payload);
    if (res.success) {
      const { data } = res;
      yield put(acceptBookingSuccess(data));
    } else {
      yield put(acceptBookingFailure(res.message));
    }
  } catch (error) {
    yield put(acceptBookingFailure(error?.toString()));
  }
}

function* rejectBookingRequest(action: PayloadAction<IRejectBookingRequest>) {
  try {
    yield put(rejectBooking());
    const res: IGetSingleBookingResponse = yield call(BookingApi.rejectBooking, action.payload);
    if (res.success) {
      const { data } = res;
      yield put(rejectBookingSuccess(data));
    } else {
      yield put(rejectBookingFailure(res.message));
    }
  } catch (error) {
    yield put(rejectBookingFailure(error?.toString()));
  }
}

function* startBookingRequest(action: PayloadAction<IStartBookingRequest>) {
  try {
    yield put(startBooking());
    const res: IStartBookingResponse = yield call(BookingApi.startBooking, action.payload);
    if (res.success) {
      const { data } = res;
      yield put(startBookingSuccess(data));
    } else {
      yield put(startBookingFailure(res.message));
    }
  } catch (error) {
    yield put(startBookingFailure(error?.toString()));
  }
}

function* removeBookingRequest(action: PayloadAction<IRemoveBookingRequest>) {
  try {
    yield put(removeBooking());
    const res: IRemoveBookingResponse = yield call(BookingApi.removeBooking, action.payload);
    if (res.success) {
      const { data } = res;
      yield put(removeBookingSuccess(data));
    } else {
      yield put(removeBookingFailure(res.message));
    }
  } catch (error) {
    yield put(removeBookingFailure(error?.toString()));
  }
}

function* updateBookingRequest(action: PayloadAction<IUpdateBookingRequest>) {
  try {
    yield put(updateBooking());
    const res: IUpdateBookingResponse = yield call(BookingApi.updateBooking, action.payload);
    if (res.success) {
      const { data } = res;
      yield put(updateBookingSuccess(data));
    } else {
      yield put(updateBookingFailure(res.message));
    }
  } catch (error) {
    yield put(updateBookingFailure(error?.toString()));
  }
}

function* getCreateBookingFun(action: PayloadAction<BookingCreate>) {
  try {
    yield put(getCreateBooking());
    const res: IGetSingleBookingResponse = yield call(BookingApi.getBookingCreateApi, action.payload);
    if (res.success) {
      yield put(getCreateBookingSuccess(res?.data));
    } else {
      yield put(getCreateBookingFailure(res.message));
    }
  } catch (error) {
    const err = error as { message: string; meta: string };
    yield put(getCreateBookingFailure(err?.meta));
  }
}

function* resetBookingRequest() {
  yield put(resetBookingState());
}

function* getMyBookingsRequest() {
  try {
    yield put(getMyBookings());
    const res: IGetMyBookingResponse = yield call(BookingApi.getMyBookingApi);
    if (res.success) {
      yield put(getMyBookingsSuccess(res.data));
    } else {
      yield put(getMyBookingsFailure(res.message));
    }
  } catch (error) {
    const err = error as { message: string; meta: string };
    yield put(getMyBookingsFailure(err?.meta));
  }
}

export function* watchBooking() {
  yield takeLatest(actionTypes.CREATE_BOOKING, createBookingRequest);
  yield takeLatest(actionTypes.GET_SINGLE_BOOKING, getSingleBookingRequest);
  yield takeLatest(actionTypes.GET_BOOKINGS, getBookingsRequest);
  yield takeLatest(actionTypes.ACCEPT_BOOKING, acceptBookingRequest);
  yield takeLatest(actionTypes.REJECT_BOOKING, rejectBookingRequest);
  yield takeLatest(actionTypes.START_BOOKING, startBookingRequest);
  yield takeLatest(actionTypes.REMOVE_BOOKING, removeBookingRequest);
  yield takeLatest(actionTypes.UPDATE_BOOKING, updateBookingRequest);
  yield takeLatest(actionTypes.RESET_BOOKING, resetBookingRequest);
  yield takeLatest(actionTypes.GET_CREATE_BOOKING, getCreateBookingFun);
  yield takeLatest(actionTypes.GET_MY_BOOKINGS, getMyBookingsRequest);
}
