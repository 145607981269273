export const RouterName = {
  initialPage: '/',
  homePage: '/home',
  loginPage: '/login',
  dashBoard: '/dash-board',
  newsFeed: '/news-feed',
  articles: '/articles',
  videos: '/videos',
  podcasts: '/podcasts',
  journal: '/journal',
  content: '/content',
  profile: '/profile',
  detailsMyPlaylist: '/dash-board/details-my-playlist?/:idMyPlaylist',
  eventDetail: '/event/eventDetail/:id',
  eventDiscussionBoard: '/news-feed/event-discussion/:id',
  event: '/upcoming-events',
  pastEvent: '/past-events',
  moneyCalculators: '/money-calculators',
  budgetPlanner: '/money-calculators/budget-planner',
  homeLoan: '/money-calculator/home-loan-savings-calculator',
  homeSurvey: '/surveys',
  payCalculator: '/money-calculators/pay-calculator',
  selfAwareness: '/surveys/self-awareness-survey',
  vakLearning: '/surveys/vak-learning-styles',
  humanMotivation: '/surveys/human-motivation',
  brainDominance: '/surveys/brain-dominance',
  powerClasses: '/power-classes',
  powerClassesDetail: 'power-classes/power-classes-detail/:id',
  powerClassesSeriesDetail: '/power-classes-series-detail/:id',
  learningDetail: 'dash-board/learning-detail/:id',
  contentDetail: '/content/content-details/:id',
  myAvailability: '/my-availability',
  zoom: '/event/Zoom/:id',
  signUp: '/sign-up',
  onBoarding: '/on-boarding',
  elevate: '/elevate',
  bookingRequestForMentee: '/booking-request-for-mentee/:id',
  engage: '/engage',
  bookingDetailMentor: '/booking-detail-mentor/:id',
  bookingDetailMentee: '/booking-detail-mentee/:id',
  mentorCalling: '/mentorCalling',
  messages: '/messages',
  editProfile: '/profile/edit-profile',
  mentors: '/mentors',
  mentorDetail: '/mentors/:id',
  articleDetail: 'articles/article-detail/:id',
  videoDetail: 'videos/video-detail/:id',
  podcastDetail: 'podcasts/podcast-detail/:id',
  journalDetail: 'journal/journal-detail/:id',
  careerStoriesDetail: 'career-stories/career-stories-detail/:id',
  profilesDetail: 'profiles/profile-detail/:id',
  myPlaylist: '/my-playlist',
  faq: '/faq',
  contactUs: '/contact-us',
  notifications: '/notifications',
  myProgress: '/my-progress',
  myBooking: '/my-booking',
  myJournal: '/my-journal',
  accountSettings: '/account-settings',
  joinNow: '/my-booking/join-now/:id',
  newsFeedDetails: 'news-feed/news-feed-detail/:id',
  topicLeaderShip: '/topic/leadership',
  womenLoveTech: '/women-love-tech',
  aboutTheStory: '/the-story',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
};
