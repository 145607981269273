import { ICreateBookingData, IMyBooking, ISingleBookingData } from '@/shared/interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface BookingState {
  createBookingData: ICreateBookingData | null;
  isCreateBookingSuccess: boolean;
  singleBookingData: ISingleBookingData | null;
  listBookingData: ISingleBookingData[] | null;
  acceptBookingData: ICreateBookingData | null;
  isAcceptBookingSuccess: boolean;
  rejectBookingData: ICreateBookingData | null;
  isRejectBookingSuccess: boolean;
  startBookingData: ICreateBookingData | null;
  removeBookingData: ICreateBookingData | null;
  updateBookingData: ICreateBookingData | null;
  loading: boolean;
  loadingUpdateBooking: boolean;
  loadingJoinSession: boolean;
  error?: string;
  isUpdateBookingSuccess: boolean;
  isCancelBookingSuccess: boolean;
  isStartBookingSuccess: boolean;
  isGetBookingSuccess: boolean;
  loadingGetAllBookings: boolean;
  dataGetCreateBooking: ISingleBookingData | null;
  loadingGetCreateBooking: boolean;
  errorGetCreateBooking?: string;
  getCreateBookingSuccess: boolean;
  getBookingSuccess: boolean;
  myBookings: IMyBooking | null;
}

const initialState: BookingState = {
  createBookingData: null,
  isCreateBookingSuccess: false,
  singleBookingData: null,
  listBookingData: null,
  acceptBookingData: null,
  isAcceptBookingSuccess: false,
  rejectBookingData: null,
  isRejectBookingSuccess: false,
  startBookingData: null,
  removeBookingData: null,
  updateBookingData: null,
  loading: false,
  loadingUpdateBooking: false,
  loadingJoinSession: false,
  error: undefined,
  isUpdateBookingSuccess: false,
  isCancelBookingSuccess: false,
  isStartBookingSuccess: false,
  isGetBookingSuccess: false,
  loadingGetAllBookings: false,
  dataGetCreateBooking: null,
  loadingGetCreateBooking: false,
  getCreateBookingSuccess: false,
  errorGetCreateBooking: undefined,
  getBookingSuccess: false,
  myBookings: null,
};

export const bookingSlice = createSlice({
  name: 'BookingState',
  initialState,
  reducers: {
    createBooking: (state: BookingState) => {
      state.loading = true;
      state.error = undefined;
      state.isCreateBookingSuccess = false;
    },
    createBookingSuccess: (state: BookingState, action: PayloadAction<ICreateBookingData>) => {
      state.loading = false;
      state.error = undefined;
      state.isCreateBookingSuccess = true;
      state.createBookingData = action.payload;
    },
    createBookingFailure: (state: BookingState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.createBookingData = null;
      state.error = action.payload;
    },
    resetCreateBookingSuccess: (state) => {
      state.isCreateBookingSuccess = false;
    },
    getSingleBooking: (state: BookingState) => {
      state.loading = true;
      state.error = undefined;
    },
    getSingleBookingSuccess: (state: BookingState, action: PayloadAction<ISingleBookingData>) => {
      state.singleBookingData = action.payload;
      state.loading = false;
      state.isGetBookingSuccess = true;
      state.error = undefined;
    },
    getSingleBookingFailure: (state: BookingState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.singleBookingData = null;
      state.error = action.payload;
    },
    getBookings: (state: BookingState) => {
      state.loadingGetAllBookings = true;
      state.error = undefined;
      state.getBookingSuccess = false;
    },
    getBookingsSuccess: (state: BookingState, action: PayloadAction<ISingleBookingData[]>) => {
      state.loadingGetAllBookings = false;
      state.listBookingData = action.payload;
      state.getBookingSuccess = true;
      state.error = undefined;
    },
    getBookingsFailure: (state: BookingState, action: PayloadAction<string | undefined>) => {
      state.loadingGetAllBookings = false;
      state.listBookingData = null;
      state.error = action.payload;
      state.getBookingSuccess = false;
    },
    clearGetBooking: (state: BookingState) => {
      state.loadingGetAllBookings = false;
      state.listBookingData = null;
      state.error = undefined;
      state.getBookingSuccess = false;
      state.isUpdateBookingSuccess = false;
    },
    acceptBooking: (state: BookingState) => {
      state.loading = true;
      state.error = undefined;
    },

    acceptBookingSuccess: (state: BookingState, action: PayloadAction<ICreateBookingData>) => {
      state.loading = false;
      state.acceptBookingData = action.payload;
      state.error = undefined;
      state.isAcceptBookingSuccess = true;
    },

    acceptBookingFailure: (state: BookingState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.error = action.payload;
      state.acceptBookingData = null;
    },

    acceptBookingClear: (state: BookingState) => {
      state.loading = false;
      state.acceptBookingData = null;
      state.error = undefined;
      state.isAcceptBookingSuccess = false;
    },

    rejectBooking: (state: BookingState) => {
      state.loading = true;
      state.error = undefined;
    },

    rejectBookingSuccess: (state: BookingState, action: PayloadAction<ISingleBookingData>) => {
      state.loading = false;
      state.singleBookingData = action.payload;
      state.error = undefined;
      state.isRejectBookingSuccess = true;
    },

    rejectBookingFailure: (state: BookingState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.rejectBookingData = null;
      state.error = action.payload;
    },
    clearRejectBooking: (state: BookingState) => {
      state.loading = false;
      state.rejectBookingData = null;
      state.error = undefined;
      state.isRejectBookingSuccess = false;
    },
    startBooking: (state: BookingState) => {
      state.loadingJoinSession = true;
      state.error = undefined;
    },
    startBookingSuccess: (state: BookingState, action: PayloadAction<ICreateBookingData>) => {
      state.loadingJoinSession = false;
      state.startBookingData = action.payload;
      state.isStartBookingSuccess = true;
      state.error = undefined;
    },
    startBookingFailure: (state: BookingState, action: PayloadAction<string | undefined>) => {
      state.loadingJoinSession = false;
      state.startBookingData = null;
      state.error = action.payload;
    },

    removeBooking: (state: BookingState) => {
      state.loading = true;
      state.error = undefined;
    },
    removeBookingSuccess: (state: BookingState, action: PayloadAction<ICreateBookingData>) => {
      state.loading = false;
      state.removeBookingData = action.payload;
      state.isCancelBookingSuccess = true;
      state.error = undefined;
    },
    removeBookingFailure: (state: BookingState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.removeBookingData = null;
      state.error = action.payload;
    },

    updateBooking: (state: BookingState) => {
      state.loadingUpdateBooking = true;
      state.error = undefined;
    },
    updateBookingSuccess: (state: BookingState, action: PayloadAction<ICreateBookingData>) => {
      state.updateBookingData = action.payload;
      state.isUpdateBookingSuccess = true;
      state.error = undefined;
      state.loadingUpdateBooking = false;
    },
    updateBookingFailure: (state: BookingState, action: PayloadAction<string | undefined>) => {
      state.loadingUpdateBooking = false;
      state.updateBookingData = null;
      state.error = action.payload;
      state.isUpdateBookingSuccess = false;
    },
    clearUpdateBooking: (state: BookingState) => {
      state.loadingUpdateBooking = false;
      state.error = undefined;
      state.updateBookingData = null;
      state.isUpdateBookingSuccess = false;
    },
    getCreateBooking: (state: BookingState) => {
      state.loadingGetCreateBooking = true;
      state.dataGetCreateBooking = null;
      state.errorGetCreateBooking = undefined;
    },
    getCreateBookingSuccess: (state: BookingState, action: PayloadAction<ISingleBookingData>) => {
      state.loadingGetCreateBooking = false;
      state.dataGetCreateBooking = action.payload;
      state.errorGetCreateBooking = undefined;
      state.getCreateBookingSuccess = true;
    },
    getCreateBookingFailure: (state: BookingState, action: PayloadAction<string | undefined>) => {
      state.loadingGetCreateBooking = false;
      state.dataGetCreateBooking = null;
      state.error = action.payload;
      state.getCreateBookingSuccess = false;
    },
    resetGetCreateBooking: (state: BookingState) => {
      state.dataGetCreateBooking = null;
      state.loadingGetCreateBooking = false;
      state.errorGetCreateBooking = undefined;
      state.getCreateBookingSuccess = false;
    },
    resetBookingState: (state: BookingState) => {
      state.loading = false;
      state.error = undefined;
      state.loadingUpdateBooking = false;
      state.isCreateBookingSuccess = false;
      state.isAcceptBookingSuccess = false;
      state.isRejectBookingSuccess = false;
      state.isUpdateBookingSuccess = false;
      state.isCancelBookingSuccess = false;
      state.isStartBookingSuccess = false;
      state.isGetBookingSuccess = false;
      state.loadingGetCreateBooking = false;
      state.errorGetCreateBooking = undefined;
    },
    resetBookingDetail: (state: BookingState) => {
      state.singleBookingData = null;
      state.loading = false;
      state.isGetBookingSuccess = false;
      state.error = undefined;
    },
    getMyBookings: (state: BookingState) => {
      state.loading = true;
    },
    getMyBookingsSuccess: (state: BookingState, action: PayloadAction<IMyBooking>) => {
      state.loading = false;
      state.myBookings = action.payload;
    },
    getMyBookingsFailure: (state: BookingState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  createBooking,
  createBookingFailure,
  createBookingSuccess,
  getBookings,
  getBookingsFailure,
  getBookingsSuccess,
  getSingleBooking,
  getSingleBookingFailure,
  getSingleBookingSuccess,
  acceptBooking,
  acceptBookingFailure,
  acceptBookingSuccess,
  rejectBooking,
  rejectBookingFailure,
  rejectBookingSuccess,
  startBooking,
  startBookingFailure,
  startBookingSuccess,
  removeBooking,
  removeBookingFailure,
  removeBookingSuccess,
  updateBooking,
  updateBookingFailure,
  updateBookingSuccess,
  resetBookingState,
  resetBookingDetail,
  clearRejectBooking,
  getCreateBooking,
  getCreateBookingSuccess,
  getCreateBookingFailure,
  resetGetCreateBooking,
  resetCreateBookingSuccess,
  clearGetBooking,
  clearUpdateBooking,
  acceptBookingClear,
  getMyBookings,
  getMyBookingsSuccess,
  getMyBookingsFailure,
} = bookingSlice.actions;

export default bookingSlice.reducer;
